<template>
  <div>
    <div v-if="notices.length === 0">
      <b-alert
        show
        variant="info"
      >
        No signed notices found.
      </b-alert>
    </div>
    <div v-else>
    <b-table
      small
      responsive
      striped
      hover
      :items="notices"
      :fields="table_fields"
    >
    <template #cell(id)="data">
      <b-button
        style="width: 7em;"
        variant="outline-primary"
        size="sm"
        @click="onShowSignedNotice(data.value)"
      >
        Notice {{ data.value }}
      </b-button>
    </template>
    </b-table>
    </div>

    <b-modal
      id="notice-report-modal"
      size="xl"
      :title="show_notice_report_title"
      hide-footer
    >
      <object
        width="100%"
        height="600px"
        :data="show_notice_report_url"
        type="application/pdf"
      >
        <p>
          Unable to display PDF file.
          <a :href="show_notice_report_url">Download</a> instead.
        </p>
      </object>
    </b-modal>


  </div>
</template>

<script>
export default {
  name: "SignedNotices",
  props: {
    pws_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notices: [],
      show_notice_report_title: "",
      show_notice_report_url: "",
      table_fields: [
        { key: "id", label: "Notice ID" },
        { key: "mp_name", label: "Monitoring Period" },
        { key: "data_signame", label: "Signed By" },
        { key: "data_signame_title", label: "Signed Title" },
        { key: "data_signature_ts", label: "DT Signed" },
      ],
    }
  },
  mounted() {
    this.getSignedNotices();
  },
  methods: {
    async getSignedNotices() {
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices?pws_id=" +
        this.pws_id + "&signed=true";
      try {
        const res = await this.axios.get(url);
        console.log(res);
        this.notices = res.data.notices;
        for (const notice of this.notices) {
          notice.data_signature_ts = new Date(notice.data_signature_ts).toLocaleString();
        }
      } catch (error) {
        console.error(error);
      }
    },
    onShowSignedNotice(notice_id) {
      console.log("onShowSignedNotice", notice_id);
      this.show_notice_report_title = "Lead/Copper Notice Confirmation - " + this.pws_id_signed_in;
      this.show_notice_report_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        notice_id +
        "/confirmation";
      this.$bvModal.show("notice-report-modal");
    },
  },
  computed: {
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`;
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id;
    },
  },
};
</script>
