<template>
  <div class="ccr">
    <div v-if="signed_in && pws_id_signed_in">
      <h6>You must upload these 3 documents:</h6>
      <ol>
        <li>CCR</li>
        <li>Certification</li>
        <li>Proof of Delivery</li>
      </ol>
      <h6>The files must be in <u>PDF, TIFF, or JPEG</u> format.</h6>
      <p>
        If you are attempting to upload an MS Word file (doc or docx), please see
        <a href="https://support.microsoft.com/en-us/office/export-word-document-as-pdf-4e89b30d-9d7d-4866-af77-3af5536b974c" target="_blank">these instructions on how to convert it to PDF</a>.
      </p>
      <p>
      </p>
      <p>
        Examples of proof of delivery could include:
        <ul>
          <li>copies of water bills</li>
          <li>newspaper clippings</li>
          <li>affidavits</li>
          <li>postage receipts</li>
          <li>email messages</li>
          <li>notice given to customers</li>
        </ul>
      </p>
      <b-alert show variant="warning">
        Do <b><u>not</u></b> include any banking information (check, money order, or credit card).
      </b-alert>  
      <b-form-group label="Check to verify the Water System" label-for="verify-pws">
        <b-form-checkbox id="verify-pws"  v-model="verify_pws">
          Uploading for <b>{{ current_pws_label }}</b>
          &nbsp; <b-link to="/change-water-system">Change Water System</b-link>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Select a PDF, TIFF, or JPEG file to upload. Upload one at a time if you have multiple files." label-for="ccr-upload">
        <b-form-file id="ccr-upload"  accept="application/pdf, image/tiff, image/jpeg" v-model="ccr_upload_file"></b-form-file>
      </b-form-group>
      <b-button :disabled="!ccr_upload_file || !verify_pws || uploading" @click="onUploadCCRFile" variant="primary">
        <b-spinner small v-if="uploading"></b-spinner>
        Upload File
      </b-button>
      <div class="mt-3">
      <b-alert fade dismissible variant="success" v-model="upload_success_alert">
        File <u>{{ccr_upload_file_name}}</u> uploaded successfully. Thank you!
      </b-alert>
      <b-alert fade dismissible variant="danger" v-model="upload_failure_alert">
        File upload failed. <b>Details</b>: <span>{{upload_failure_message}}</span>
      </b-alert>
      </div>
    </div>
    <div v-else class="mt-5">
      <b-alert show variant="info">
        You must be <b-link to="signin">signed in </b-link>to <b>upload CCR files</b>.
      </b-alert>
    </div>
  </div>
</template>
<script>

export default {
  name: "CCRUploads",
  data() {
    return {
      verify_pws: false,
      ccr_upload_file: null,
      ccr_upload_file_name: '',
      upload_success_alert: false,
      upload_failure_alert: false,
      upload_failure_message: '',
      uploading: false,
    }
  },
  methods: {
    onUploadCCRFile() {
      const form_data = new FormData()
      this.ccr_upload_file_name = this.ccr_upload_file.name
      form_data.append('ccr_file', this.ccr_upload_file)
      form_data.append('pws_id', this.pws_id_signed_in)
      this.uploading = true
      this.axios.post(process.env.VUE_APP_API_URI + '/ccr-file', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        console.log(res)
        if (res.data && res.data.msg == 'OK') {
          this.upload_success_alert = true
          this.ccr_upload_file = null
        } else {
          this.upload_failure_alert = true
          this.upload_failure_message = res.data.msg || 'No details.'
        }
      }).catch((error) => {
        console.error(error)
        this.upload_failure_message = 'No details.'
        this.upload_failure_alert = true
      }).finally(() => {
        this.uploading = false
      })
    }
  },
  mounted() {
    // pass
  },
  computed: {
    pws_id_signed_in() {
      return this.$store.state.pws_id
    },
    current_pws() {
      return this.$store.state.pws
    },
    current_pws_label() {
      return `${this.current_pws.pws_name} (${this.current_pws.pws_id})`
    },
    signed_in() {
      return this.$store.state.signed_in
    },
  }
}
</script>
